<template>
    <div>
        <div class="row mt-3">
            <div class="col-md-6" style="display: flex; flex-direction: column;">
                <div class="card card-success card-outline" style="flex: 1 0 auto;">
                    <div class="card-header">
                        <h2 class="card-title">Kategorien (Online)</h2>
                        <div class="card-tools">
                            <button class="btn btn-success btn-xs" @click="showCategoryModal"><i class="fas fa-fw fa-plus"></i></button>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table table-sm table-striped">
                                        <thead>
                                            <th>Kategorie</th>
                                            <th>Aktion</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(category) in article.categories[company.id]" :key="category.id" >
                                                <td>{{ generateCategoryName(category.id) }}</td>
                                                <td>
                                                    <!-- <button class="btn btn-xs btn-warning mr-1"><i class="fas fa-fw fa-eye" /></button> -->
                                                    <button class="btn btn-xs btn-danger" @click="deleteCategory(category.id)"><i class="fas fa-fw fa-trash" /></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6" style="display: flex; flex-direction: column;">
                <div class="card card-warning card-outline" style="flex: 1 0 auto;">
                    <div class="card-header">
                        <h2 class="card-title">Ähnliche Artikel</h2>
                        <div class="card-tools">
                            <button class="btn btn-warning btn-xs" @click="showArticleModal"><i class="fas fa-fw fa-plus"></i></button>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table table-sm table-striped">
                                        <thead>
                                            <th>Artikelnummer</th>
                                            <th>Hersteller</th>
                                            <th>Name</th>
                                            <th>Aktion</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(article) in article.similar[company.id]" :key="article.id" :class="article.active == 0 ? 'table-danger' : ''">
                                                <td>{{ article.details.ordernumber }}</td>
                                                <!-- <td>{{ generateProducerName(article.producer_id) }}</td> -->
                                                <td>{{ article.producer.name }}</td>
                                                <td>{{ article.name }}</td>
                                                <td>
                                                    <router-link class="btn btn-xs btn-warning mr-1" :to="{name: 'articles.details', params:{id:article.id}}" v-if="$auth.check('articles.edit')"><i class="fas fa-fw fa-eye"></i></router-link>
                                                    <button class="btn btn-xs btn-danger" @click="deleteSimilar(article.id)"><i class="fas fa-fw fa-trash" /></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-md-6">
                <div class="card card-danger card-outline">
                    <div class="card-header">
                        <h2 class="card-title">Google Merchant Center</h2>
                    </div>

                    <div class="card-body">
                        <div class="form-group">
                            <label for="">Diesen Artikel in das Google Merchant Center exportieren?</label>
                            <select class="form-control form-control-sm" v-model="google.export" :class="{'is-invalid': google.errors.has('export')}">
                                <option value="1">Ja</option>
                                <option value="0">Nein</option>
                            </select>
                            <has-error :form="google" field="export"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="">Google Shopping Category</label>
                            <v-select label="name" v-model="google.category" :reduce="category => category.id" :options="categories" @search="fetchCategories" :class="{'is-invalid': google.errors.has('category')}">

                            </v-select>
                            <has-error :form="google" field="category"></has-error>
                        </div>

                    </div>

                    <div class="card-footer text-right">
                        <button type="button" class="btn btn-primary btn-sm" @click="saveMerchantCenter"><i class="fas fa-save"></i> Speichern</button>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="card card-danger card-outline">
                    <div class="card-header">
                        <h2 class="card-title">Facebook Shopping Catalog</h2>
                    </div>

                    <div class="card-body">
                        <div class="form-group">
                            <label for="">Diesen Artikel in den Facebook Shopping Catalog exportieren?</label>
                            <select class="form-control form-control-sm" v-model="facebook.export" :class="{'is-invalid': facebook.errors.has('export')}">
                                <option value="1">Ja</option>
                                <option value="0">Nein</option>
                            </select>
                            <has-error :form="facebook" field="export"></has-error>
                        </div>

                        <div class="form-group">
                            <label for="">Facebook Shopping Category</label>
                            <v-select label="name" v-model="facebook.category" :reduce="category => category.id" :options="facebook_categories" @search="fetchFacebookCategories" :class="{'is-invalid': facebook.errors.has('category')}">

                            </v-select>
                            <has-error :form="facebook" field="category"></has-error>
                        </div>

                    </div>

                    <div class="card-footer text-right">
                        <button type="button" class="btn btn-primary btn-sm" @click="saveFacebookCatalog"><i class="fas fa-save"></i> Speichern</button>
                    </div>
                </div>
            </div>
        </div>


        <b-modal :id="'articleModal' + company.prefix" title="Ähnliche Artikel verwalten" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="articleModalOk">
                <b-row class="mb-3" >
                    <b-col md="3">
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="loadAvailableArticles" v-model="articleModal.search" />
                            <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadAvailableArticles">
                                <i class="fas fa-search"></i>
                            </button>
                        </div>
                    </b-col>
                </b-row>
            
                <b-row v-if="availableArticles.data.length > 0">
                    <b-col>
                        <table class="table table-striped">
                            <thead>
                                <tr data-widget="expandable-table" aria-expanded="false">
                                    <th scope="col">#</th>
                                    <th>
                                        <a href="#" @click.prevent="changeSort('ordernumber')">Artikelnummer</a>
                                        <span v-if="this.articleModal.params.sort_field == 'ordernumber' && this.articleModal.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.articleModal.params.sort_field == 'ordernumber' && this.articleModal.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th>
                                        <a href="#" @click.prevent="changeSort('name')">Artikelname</a>
                                        <span v-if="this.articleModal.params.sort_field == 'name' && this.articleModal.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.articleModal.params.sort_field == 'name' && this.articleModal.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th><a href="#" @click.prevent="changeSort('producer')">Hersteller</a>
                                        <span v-if="this.articleModal.params.sort_field == 'producer' && this.articleModal.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.articleModal.params.sort_field == 'producer' && this.articleModal.params.sort_direction == 'desc'" >&darr;</span></th>
                                    <th>
                                        <a href="#" @click.prevent="changeSort('price')">Preis</a>
                                        <span v-if="this.articleModal.params.sort_field == 'price' && this.articleModal.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.articleModal.params.sort_field == 'price' && this.articleModal.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th>
                                        <a href="#" @click.prevent="changeSort('tax')">Steuersatz</a>
                                        <span v-if="this.articleModal.params.sort_field == 'tax' && this.articleModal.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.articleModal.params.sort_field == 'tax' && this.articleModal.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                </tr>
                                <tr class="expandable-body">
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                    <th>
                                        <select v-model="articleModal.params.producer_id" class="form-control form-control-sm">
                                            <option :value="''">-- Filter Hersteller --</option>
                                            <option v-for="producer in producers" :key="producer.id" :value="producer.id">{{ producer.name }}</option>
                                        </select>
                                    </th>
                                    <th>&nbsp;</th>
                                    <th>
                                        <select v-model="articleModal.params.tax_id" class="form-control form-control-sm">
                                            <option :value="''">-- Filter Steuer --</option>
                                            <option v-for="tax in taxes" :key="tax.id" :value="tax.id">{{ tax.description }}</option>
                                        </select>
                                    </th>
                                    <!-- <th>&nbsp;</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="article in availableArticles.data" :key="article.id" :class="article.active == 0 ? 'table-danger' : ''">
                                    <td><input type="checkbox" v-model="form.articles" :value="article.id" /></td>
                                    <td>{{ article.ordernumber }}</td>
                                    <td>{{ article.name }}</td>
                                    <td>{{ article.producer }}</td>
                                    <td>{{ article.price | toCurrency}}</td>
                                    <td>{{ article.tax }}</td>
                                    <!-- <td>
                                        <button class="btn btn-xs btn-primary" @click="addSimilar(index)"><i class="fas fa-fw fa-plus"></i></button>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>

                <b-row v-if="availableArticles.data.length > 0">
                    <pagination class="float-left" :data="availableArticles" @pagination-change-page="loadAvailableArticles" :limit="3"></pagination>
                </b-row>
                
                <b-row v-if="availableArticles.data.length == 0">
                    <b-col>
                        <h4>Es konnten keine Artikel gefunden werden.</h4>
                    </b-col>
                </b-row>
        </b-modal>

        <b-modal :id="'categoryModal' + company.prefix" title="Kategorien verwalten" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="categoryModalOk">            
                <b-row v-if="company.treeview.length > 0">
                    <b-col>
                        <table class="table table-striped">
                            <thead>
                                <th>#</th>
                                <th>Kategorie</th>
                                <!-- <th>Aktion</th> -->
                            </thead>
                            <tbody>
                                <tr v-for="(category) in company.treeview" :key="category.id">
                                    <!-- <td><input type="checkbox" v-model="form.articles" :value="article.id" /></td> -->
                                    <td><input type="checkbox" v-model="categoriesForm.categories" :value="category.id" /></td>
                                    <td>{{ category.name }}</td>
                                    <!-- <td>
                                        <button class="btn btn-xs btn-primary" @click="addCategory(index)"><i class="fas fa-fw fa-plus"></i></button>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>

                <b-row v-if="company.treeview.length == 0">
                    <b-col>
                        <h4>Es konnten keine Kategorien gefunden werden.</h4>
                    </b-col>
                </b-row>
        </b-modal>
    </div>
</template>

<script>

export default {
    name: "ArticleCompany",
    props: [
        'company',
        'article',
        'taxes',
        'producers',
    ],

    watch:{
        'article': {
            handler() {
                this.selectedSimilar = this.article.similar[this.company.id];

                if(this.article.facebook == null)
                {
                    this.facebook.export = 0;
                    this.facebook.category = null;
                }

                else{
                    var data2 = this.article.facebook.find(item => item.company_id == this.company.id);
                    if(data2 == undefined)
                    {
                        this.facebook.export = 0;
                        this.facebook.category = null;
                    }
                    else
                    {
                        this.facebook.export = data2.merchant_allow_export;
                        if(data2.facebook == null){
                            this.facebook.category = null;
                        }
                        else
                        {
                            this.facebook.category = data2.facebook.id;
                        }
                    }
                }

                if(this.article.merchant == null)
                {
                    this.google.export = 0;
                    this.google.category = null;
                }

                else{
                    var data = this.article.merchant.find(item => item.company_id == this.company.id);
                    if(data == undefined)
                    {
                        this.google.export = 0;
                        this.google.category = null;
                    }
                    else
                    {
                        this.google.export = data.merchant_allow_export;
                        if(data.google == null){
                            this.google.category = null;
                        }
                        else
                        {
                            this.google.category = data.google.id;
                        }
                    }
                }

            },
            deep:true,
        },

        'articleModal.params': {
            handler () {
                this.loadAvailableArticles();
            },
            deep: true
        },
    },

    data(){
        return{
            categoriesForm: new window.Form({
                categories: [],
                article_id: "",
            }),

            form: new window.Form({
                articles: [],
                article_id: "",
                company_id: "",
            }),

            availableArticles: {
                data: [],
            },
            google: new window.Form({
                'category': '',
                'export': '',
                'company_id': '',
            }),
            facebook: new window.Form({
                'category': '',
                'export': '',
                'company_id': '',
            }),
            categories:[],
            facebook_categories: [],
            search: '',
            facebook_search: '',
            articleModal: {
                params: {
                    sort_field: 'ordernumber',
                    sort_direction: 'asc',
                    producer_id: '',
                    tax_id: '',
                    per_page: 25,
                    category_id: '',
                    company_id: this.company.id
                },
                search: '',
            }
            
        }
    },

    mounted(){    
        // $('select[name="categories_'+ this.company.prefix +'"]').bootstrapDualListbox({
        //     nonSelectedListLabel: 'Verfügbar',
        //     selectedListLabel: 'Zugewiesen',
        //     infoText: false,
        //     infoTextFiltered: '{0} bis {1}',
        // }); 
    },

    methods:{

        deleteCategory(id){
            this.$swal({
                title: "Möchtest du die Kategorie wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.form
                        .delete("/articles/" + this.article.id+ "/removeCategory/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Kategorie erfolgreich gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            // this.$parent.loadArticle();
                            this.$emit('articleUpdate');
                        })
                        .catch((error) => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: error.response.data.error,
                            });
                        });
                }
            });
        },

        categoryModalOk(){
            this.categoriesForm.article_id = this.article.id;
            this.categoriesForm
                .post("/articles/setCategories")
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Kategorien gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$bvModal.hide("categoryModal" + this.company.prefix);
                    this.categoriesForm.categories = [];
                    // this.$parent.loadArticle();
                    this.$emit('articleUpdate');
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        showCategoryModal(){
            this.$bvModal.show("categoryModal" + this.company.prefix);
        },

        deleteSimilar(id){
            this.$swal({
                title: "Möchtest du den Cross-Selling Artikel wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.form
                        .delete("/articles/" + this.article.id+ "/removeSimiliar/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Artikel erfolgreich gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            // this.$parent.loadArticle();
                            this.$emit('articleUpdate');
                        })
                        .catch((error) => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: error.response.data.error,
                            });
                        });
                }
            });
        },

        articleModalOk(){
            this.form.article_id = this.$route.params.id;
            this.form.company_id = this.company.id;
            this.form
                .post("/articles/addSimiliar")
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Crosseling Artikel wurden gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$bvModal.hide("articleModal" + this.company.prefix);
                    this.form.articles = [];
                    this.$emit('articleUpdate');
                    // this.$parent.loadArticle();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });       
        },

        loadAvailableArticles(page = 1){
            this.$Progress.start();
            // this.availableArticles = [];
            this.axios
                .get("/articles", {
                    params: {
                        page,
                        search: this.articleModal.search,
                        ...this.articleModal.params
                    }
                })
                .then((response) => {
                    this.availableArticles = response.data;
                    this.$Progress.finish();
                    //this.loadArticle();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        showArticleModal(){
            this.$bvModal.show("articleModal" + this.company.prefix);
            this.availableArticles = {
                data: []
            };
            this.loadAvailableArticles();
        },

        generateProducerName(id){
            var producer = this.producers.find(item => item.id == id);

            if(producer == undefined)
            {
                return "Unbekannter Hersteller";
            }
            else
            {
                return producer.name;
            }
        },

        generateCategoryName(category){
            var data = this.company.treeview.find(item => item.id == category);

            if(data == undefined)
            {
                return "Unbekannte Kategorie";
            }
            else
            {
                return data.name;
            }
        },

        saveSimilarArticles()
        {
            this.axios
                .post("/articles/addSimiliar",{
                    'articles': this.article.similar[this.company.id],
                    'company_id': this.company.id,
                    'article_id': this.article.id,
                })
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Crosseling Artikel wurden gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    // this.$parent.loadArticle();
                    this.$emit('articleUpdate');
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        saveMerchantCenter()
        {
            this.google.company_id = this.company.id;
            this.google
                .post("/articles/" + this.article.id + '/updateGoogle')
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Google Daten wurden gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    // this.$parent.loadArticle();
                    this.$emit('articleUpdate');
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        saveFacebookCatalog()
        {
            this.facebook.company_id = this.company.id;
            this.facebook
                .post("/articles/" + this.article.id + '/updateFacebook')
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Facebook Daten wurden gespeichert",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    // this.$parent.loadArticle();
                    this.$emit('articleUpdate');
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },


        fetchFacebookCategories(search, loading)
        {
            loading(true);
            this.facebook_search = search;
            if(this.facebook_search.length > 3){
                this.loadFacebookCategories();
            }
            loading(false);
        },

        fetchCategories(search, loading)
        {
            loading(true);
            this.search = search;
            if(this.search.length > 3){
                this.loadCategories();
            }
            loading(false);
        },

        loadFacebookCategories(){
            this.axios
                .get("/marketplace/facebook/categories?search=" + this.search)
                .then((response) => {
                    this.facebook_categories = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadCategories()
        {
            this.axios
                .get("/marketplace/google/categories?search=" + this.search)
                .then((response) => {
                    this.categories = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }
    },

    created(){
        // this.google.category = this.article.google_shopping_category == null ? null : this.article.google_shopping_category.id;
        // this.google.export = this.article.no_google_export;
        if(this.article.merchant == null)
        {
            this.google.export = 0;
            this.google.category = null;
        }

        else{
            var data = this.article.merchant.find(item => item.company_id == this.company.id);
            var facebook = this.article.facebook.find(item => item.company_id == this.company.id);
            if(data == undefined)
            {
                this.google.export = 0;
                this.google.category = null;
            }
            else
            {
                this.google.export = data.merchant_allow_export;
                if(data.google == null){
                    this.google.category = null;
                }
                else
                {
                    this.google.category = data.google.id;
                }
            }

            if(facebook == undefined)
            {
                this.facebook.export = 0;
                this.facebook.category = null;
            }
            else
            {
                this.facebook.export = facebook.merchant_allow_export;
                if(facebook.facebook == null){
                    this.facebook.category = null;
                }
                else
                {
                    this.facebook.category = facebook.facebook.id;
                }
            }
        }
        this.loadCategories();
        this.loadFacebookCategories();
    }
}
</script>

<style>

</style>
